import React from 'react';
// import Service from 'views/Service';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import PICTURE0 from '../../static/picture1.gif';
import PICTURE1 from '../../static/1.png';

import PICTURE2 from '../../static/2.png';

import COMMUNICATION_INTERFACE from '../../static/communication-interface.png';
import COMMUNICATION_INTERFACE_2 from '../../static/communication-interface-2.jpeg';

const ProjectPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          position: 'relative',
        }}
      >
        {/* <Container position={'relative'} zIndex={3}> */}
        {/* <Box bgcolor={'alternate.main'}> */}
        <Container position={'relative'} zIndex={3}>
          <div>
            <img alt="PICTURE1" src={PICTURE0} width={'100%'} />
            <h1
              style={{
                position: 'absolute',
                top: 120,
                marginLeft: 0,
                marginRight: 0,
                width: '100%',
                textAlign: 'center',
              }}
            >
              Thai Open SCADA Platform
            </h1>
          </div>
          <p>
            &nbsp;&nbsp;&nbsp;
            โครงการวิจัยและพัฒนาแพลตฟอร์มเปิดสเกด้าไทยสำหรับอุตสาหกรรมเอสเอ็มอีมุ่งวิจัยและพัฒนาซอฟต์แวร์แพลตฟอร์ม
            SCADA ที่เป็นระบบเปิด เพื่อให้ง่ายต่อการพัฒนาต่อยอด ขยายผล
            และนำไปติดตั้งใช้งานอย่างแพร่หลายในภาคอุตสาหกรรมโดยเฉพาะกลุ่ม SMEs
            และเป็นประโยชน์กับชุมชนนักพัฒนาซอฟต์แวร์สำหรับ Industry 4.0 ของไทย
            ซอฟต์แวร์แพลตฟอร์มที่วิจัยและพัฒนาขึ้นมานี้มีชื่อว่า “Thai Open
            SCADA” โดยพัฒนาต่อยอดมาจาก Web SCADA ซึ่งเป็นซอฟต์แวร์ต้นแบบ SCADA
            ขั้นพื้นฐาน <br />
            <br />
            &nbsp;&nbsp;&nbsp;โครงการนี้ได้มีการวิจัยพัฒนาซอฟต์แวร์แพลตฟอร์ม
            SCADA สำหรับ Industry 4.0
            ขึ้นโดยเริ่มจากศึกษาเปรียบเทียบจากผลิตภัณฑ์ของต่างประเทศ การรวบรวม
            Requirement จากบริษัท Consortium ผู้ร่วมให้ทุนวิจัย
            การออกแบบซอฟต์แวร์ SCADA ขึ้นมาใหม่ โดยอาศัยพื้นฐานการพัฒนา Web
            SCADA โดยอาศัยหลักการวิเคราะห์ออกแบบเชิง Object-Oriented ทั้งหมด
            และพัฒนาด้วยแพลตฟอร์มเว็บ โดยที่ฝั่ง Server ใช้ Django Web Framework
            ที่เป็นภาษา Python ในการพัฒนา และฝั่ง Web Client ใช้ React Framework
            ที่เป็นภาษา JavaScript ร่วมกับ HTML โดยทั้งหมดเป็นซอฟต์แวร์ Open
            Source ทั้งสิ้น
          </p>
          <Box sx={{ textAlign: 'center' }}>
            <Card>
              <h2 style={{ textAlign: 'center' }}>องค์ประกอบของแพลตฟอร์ม</h2>

              <p>
                <Grid container>
                  <Grid item xs={12} sm={7}>
                    <img
                      src={PICTURE1}
                      width={'100%'}
                      style={{ maxWidth: '700px' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={5} style={{ textAlign: 'left' }}>
                    <h4>
                      องค์ประกอบของแพลตฟอร์ม
                      <br />
                      สำหรับ Industry 4.0 มี 8 ส่วนดังนี้
                    </h4>

                    <ul style={{ textAlign: 'left' }}>
                      <li>SCADA (Core)</li>
                      <li>IoT Interface</li>
                      <li> Intelligent Agent</li>
                      <li> Real-time Agent Communication</li>
                      <li>Augmented/Virtual Reality</li>
                      <li> GIS</li>
                      <li>Automatic Workflow</li>
                      <li>Security </li>
                    </ul>
                  </Grid>
                </Grid>
              </p>
            </Card>
            {/* <Divider /> */}
            <Card sx={{ mt: 2 }}>
              <p>
                <h2>องค์ประกอบตามฟังก์ชั่นการทำงานของแพลตฟอร์ม</h2>
                <img
                  src={PICTURE2}
                  width={'100%'}
                  style={{ maxWidth: '500px' }}
                />
                <br />
              </p>
            </Card>

            <p style={{ textAlign: 'left' }}>
              &nbsp;&nbsp;&nbsp; การพัฒนาซอฟต์แวร์ฝั่ง Server
              ประกอบด้วยการพัฒนาส่วน (1) SCADA ที่เป็นแกนหลักของแพลตฟอร์ม
              ทำหน้าที่บริหารจัดการ
              เฝ้าติดตามตรวจสอบและควบคุมการทำงานของเครื่องจักรโดยการสื่อสารกับเครื่องจักรผ่านส่วน
              (2) IoT Interface ซึ่งเป็นส่วน Worker
              ที่เชื่อมต่อรับส่งข้อมูลโดยอาศัยโปรโทคอลการสื่อสารผ่านทางสายสื่อสารหรือการสื่อสารไร้สายตลอดเวลา
              ระบบได้รับการออกแบบมาให้สามารถขยายการรองรับโปรโทคอลด้านอุตสาหกรรมอัตโนมัติ
              ได้แก่ ModBus, CAN, EtherCAT, OPC-UA ฯลฯ และ ด้าน IoT (Internet of
              Things) ได้แก่ LoRA, MQTT ฯลฯ ได้เพิ่มขึ้นเรื่อยๆ โดยไม่จำกัด
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;นอกจากนี้ยังได้เพิ่มขีดความสามารถด้านปัญญาประดิษฐ์ให้กับ
              SCADA (Core) โดยพัฒนา (3) Agent ไว้คอยช่วย SCADA
              คิดตัดสินใจโดยอาศัยกฎความรู้ ในการแจ้งเตือน
              ในการทำงานอัตโนมัติต่างๆ และการคิดวิเคราะห์แบบ (4) Real-time Agent
              Communication อีกทั้ง SCADA
              ที่พัฒนาขึ้นยังสามารถจัดเก็บและประมวลผลข้อมูลเชิงเรขาคณิตและเชิงพื้นที่ผ่านความสามารถทางด้าน
              (5) ระบบภูมิสารสนเทศ (GIS) อีกด้วย <br />
              <br />
              &nbsp;&nbsp;&nbsp;สำหรับการพัฒนาซอฟต์แวร์ฝั่ง Client
              เป็นการพัฒนาส่วนแสดงผล User Interface และ การแสดงผล Widget ทั้งแบบ
              2 มิติและ 3 มิติ ซึ่งประการหลังอาศัยส่วนแสดงผล 3 มิติ ด้วย (7) VR
              (Virtual Reality)
              เมนูการใช้งานโปรแกรมสามารถรองรับการเปลี่ยนภาษาเป็นภาษาต่างประเทศได้หลากหลายไม่จำกัด
              การปรับเปลี่นเฉดสีของหน้าจอเป็นโทนสีตามที่ผู้ใช้ชื่นชอบ
              ทั้งหมดนี้ช่วยสร้างความแตกต่างให้กับ Thai Open SCADA
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;ด้วยองค์ประกอบความสามารถมากมายหลายประการของแพลตฟอร์ม
              Thai Open SCADA
              ซึ่งได้รับการออกแบบพัฒนาให้เข้ากันได้อย่างลงตัวทั้งหมด
              และยังสามารถพัฒนาต่อขยายเปิดกว้างได้ในทุกๆส่วน โดยอาศัยซอฟต์แวร์
              Open Source ทั้งนี้เพื่อให้เป็นแพลตฟอร์มระบบเปิดของซอฟต์แวร์ SCADA
              สำหรับกลุ่มบริษัท SME ของไทยที่จะมุ่งพัฒนาไปสู่ Industry 4.0
              และที่สำคัญซอฟต์แวร์แพลตฟอร์มนี้ ยังสามาถติดตั้งใช้งานบนเครื่อง
              Raspberry Pi 4 ขนาด RAM 8 GB และ storage ขนาด 32 GB ราคาใม่เกิน
              3,000 บาท ได้เป็นอย่างดี
              ซึ่งผลงานการวิจัยและพัฒนานนี้มีศักยภาพที่จะกลายเป็นเครื่องจักรอันสำคัญที่จะนำไปใช้ในการแข่งขันและขับเคลื่อนให้เกิดการเติบโตของอุตสาหกรรม
              SMEs ไทยในโลกยุคหลัง Covid-16 ต่อจากนี้ไป
            </p>

            <Card sx={{ mt: 2 }}>
              <h2 style={{ textAlign: 'center' }}>Communication Interface</h2>

              <p>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <img
                      src={COMMUNICATION_INTERFACE}
                      width={'100%'}
                      style={{ maxWidth: 250, maxHeight: 350 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                    <h4>
                      ความสามารถของซอฟต์แวร์ Open SCADA
                      <br />
                      ในการเชื่อมต่อสื่อสาร ณ ปัจจุบัน
                    </h4>

                    <ul style={{ textAlign: 'left' }}>
                      <li>Modbus RTU และ Modbus TCP</li>
                      <li>PLC ผ่าน Modbus</li>
                      <li>PLC ผ่าน Ethernet</li>
                      <li>PLC (Mitsubishi, WECON)</li>
                      <li>OPC-UA</li>
                      <li>WiFi</li>
                      <li>ZigBee</li>
                      <li>MQTT</li>
                      <li>LoRa</li>
                    </ul>
                  </Grid>
                </Grid>
              </p>

              <p>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <img
                      src={COMMUNICATION_INTERFACE_2}
                      width={'100%'}
                      style={{ maxWidth: 300, maxHeight: 300 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                    <h4>โปรโทคอลรองรับเพิ่มเข้ามา</h4>

                    <ul style={{ textAlign: 'left' }}>
                      <li>CAN</li>
                      <li> VDO Streaming</li>
                      <li> NB-IoT & 3G/4G/5G</li>
                      <li>EtherCAT</li>
                      <li> PLC ของ Siemens</li>
                    </ul>
                  </Grid>
                </Grid>
              </p>
            </Card>

            {/* <Divider /> */}
            {/* </Box> */}
            {/* </Container> */}
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default ProjectPage;
